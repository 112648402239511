// src/pages/ActiveTestruns.js
import React from 'react';

const ActiveTestruns = () => {
  return (
    
    <div>

        <h1>Active Testruns</h1>
        <p>This page is only accessible to authenticated users.</p>
    </div>
  );
};

export default ActiveTestruns;