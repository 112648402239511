import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LevelsHistogram from '../graphing/LevelsHistogram'

const DropPreviewThumbnail = ({ data, timeSeriesData, imageList, imageFileInfo, setImageFileInfo, activeImageIndex = 0, setActiveImageIndex}) => {

    
    const [imageUrl, setImageUrl] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);


    // Modal Management
    const [show, setShow] = useState(false);
    const [showHistogram, setShowHistogram] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    
    const toggleHistogram = () => {setShowHistogram(showHistogram => !showHistogram)};

    useEffect(() => {

        const requestDropPreview = async () => {
        
            console.log("Requesting image:", imageFileInfo.previewUrl);
            try {
                const response = await fetch(imageFileInfo.previewUrl, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!response.ok) {
                    console.log("Error: ", response)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                } 

                const blob = await response.blob();
                
                const objectURL = URL.createObjectURL(blob);
                setImageUrl(objectURL);
                console.log("setImageUrl: ", imageUrl)
                
            } catch (error) {
                console.error("Error fetching image:", error);
                setImageUrl(null);
            }
        };

        if (imageFileInfo && imageFileInfo.previewUrl) {
            console.log("trying to get image: ", imageFileInfo.previewUrl)
            requestDropPreview()
        }
        console.log("Running DropGallery for image list: ", imageList)
    }, [imageList, activeImageIndex, imageFileInfo]);

    
    useEffect(() => {
        
        if (imageList.length > 0) {

            console.log("Image list obtained.")
            const fileInfo = (imageList[activeImageIndex])
            console.log("File info: ", fileInfo)
            
            
            setImageFileInfo(fileInfo)

        }
        
    } , [timeSeriesData, activeImageIndex, imageList]);



    const handleNextImage = () => {

        const nextIndex = activeImageIndex < imageList.length - 1 ? activeImageIndex + 1 : 0
        setActiveImageIndex(nextIndex);
    };

    const handlePreviousImage = () => {
        const nextIndex = activeImageIndex > 0 ? activeImageIndex - 1 : imageList.length - 1 
        setActiveImageIndex(nextIndex);
    };



    const displayThumbnail = (timeSeriesData) => {
        if (imageUrl) {
            
            return (<img key={imageUrl} src={imageUrl} alt="Drop Preview" className="image-preview" onClick={handleShow}/>)
            
        } else {
            return <div className="image-preview"></div>
        };
    }


    const displayFull = (timeSeriesData) => {
        
        if (imageUrl) {
            
                return (<img key={`${imageUrl}-full`} src={imageUrl} alt="Drop Image" className="image-full" onClick={handleNextImage}/>)
                
        } else {
            return <div className="image-full"></div>
        };
    }

    const displayHistogram = (timeSeriesData) => {

        if (showHistogram && timeSeriesData.dropImages[activeImageIndex].histogram) {
            return <LevelsHistogram show={showHistogram} data={timeSeriesData.dropImages[activeImageIndex].histogram}/>
        } else { 
            return <></>
        };
    }
    return (
        
        <div style={{ height: "100%" }}>
            {displayThumbnail(timeSeriesData)}

            {imageList ?
                <div className="horizontal-cell">
                    <Button type="button" variant="secondary" className="btn btn-primary btn-sm preview-button"  onClick={handlePreviousImage} >←</Button>
                    <span>{`${activeImageIndex}`} / {`${imageList.length}`}</span>
                    <Button type="button" variant="secondary" className="btn btn-primary btn-sm preview-button" onClick={handleNextImage} >→</Button>
                </div>
                : <></>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{height: "3rem", }} closeButton>
                    <div className="gallery-header">
                    <Modal.Title>Test {data.test_number || "--"} Drop Images</Modal.Title>
                    <div className="vr"></div>
                    <div>Serial: {data.infdev_sn || "--"}</div>
                    <div className="vr"></div>
                    <div>IV Set: {data.test_set_number || "--"} </div>
                    <div className="vr"></div>
                    <div>Fluid: {data.fluid_type || "--" }</div>
                    </div>
                </Modal.Header>
                {imageUrl ?
                <Modal.Body className="gallery-body">
                    
                    <h4>{imageFileInfo.name || "--"}</h4>
                    <div className="image-full-container">
                    {displayFull(timeSeriesData)}
                    {displayHistogram(timeSeriesData)}
                    </div>
                        
                </Modal.Body>
                    : <></>
                }
                <Modal.Footer className="horizontal-cell">
                    
                <Button variant="secondary" onClick={toggleHistogram}>
                        Levels
                </Button>
                {imageList ?
                <div className="gallery-nav">
                            <Button type="button" variant="primary" className="btn btn-primary btn-sm" onClick={handlePreviousImage} >←</Button>
                            <span>{`${activeImageIndex}`} / {`${ imageList.length }`}</span>
                            <Button type="button" variant="primary" className="btn btn-primary btn-sm" onClick={handleNextImage} >→</Button>
                </div>
                : <></>}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
        
        );
}

export default DropPreviewThumbnail;
