import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../styles/Styles.css';

export const SoftwareArtifactDownloader = () => {
    const [formData, setFormData] = useState({
        repo: '',
        pipeline_id: '',
        output_dir: '',
        filename: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const downloadGitArtifacts = async () => {
        const fetchURL = 'https://exp.test-data.amgis.us/device_software/download_ci_artifact';
        console.log("Fetching:", fetchURL);
        try {
            const response = await fetch(fetchURL, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Process the response (this will depend on how your API sends back data)
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Modal
                show={true}
                backdrop={true}
                style={{ 
                    maxWidth: 'none', 
                    '--bs-modal-width': '90vw',
                    left: '0' 
                }}>
                <Modal.Header>
                    <Modal.Title>CI Artifact Downloader</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Repository</Form.Label>
                            <Form.Control type="text" name="repo" value={formData.repo} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Pipeline ID</Form.Label>
                            <Form.Control type="text" name="pipeline_id" value={formData.pipeline_id} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Output Directory</Form.Label>
                            <Form.Control type="text" name="output_dir" value={formData.output_dir} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Filename</Form.Label>
                            <Form.Control type="text" name="filename" value={formData.filename} onChange={handleChange} />
                        </Form.Group>

                        <Button variant="primary" onClick={downloadGitArtifacts}>Download Artifacts</Button>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SoftwareArtifactDownloader;
