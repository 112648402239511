import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const BuildProgressDisplay = ({ buildProgress }) => {
  if (!buildProgress || Object.keys(buildProgress).length === 0) {
    return null; // Return null if swPickList is empty
  }

  const swPickList = buildProgress?.["sw_picklist"] || {}

  return (
    <Container className="mt-4 text-light">
      
      
      { buildProgress["status"] == "Done" ? 
        <h4> Mender Release Ready: <a href={`https://hosted.mender.io/ui/releases/${buildProgress["release_name"]}`} target="_blank">{`https://hosted.mender.io/ui/releases/${buildProgress["release_name"]}`}</a></h4>
       : <h3 ><strong>Build Progress:</strong> {buildProgress?.["status"]}</h3> }
        <Table className="mt-4 text-light" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Source Type</th>
              <th>Source</th>
              <th>Pipeline</th>
              <th>Git DL</th>
            </tr>
          </thead>
          <tbody className="text-light">
            {Object.keys(buildProgress["sw_picklist"]).map((projectName) => {
              const { sourceType, source, pipeline } = swPickList[projectName];
              const rowStyle = swPickList[projectName]?.["git_download"] ? "text-light bg-success" : "text-light"
              return (
                <tr className={rowStyle} key={projectName}>
                  <td className={rowStyle}>{projectName}</td>
                  <td className={rowStyle}>{sourceType}</td>
                  <td className={rowStyle} colSpan={sourceType === 'release' ? 2 : 1}>{source}</td>
                  {sourceType !== 'release' && <td className={rowStyle}>{pipeline}</td>}
                  <td className={rowStyle}>{swPickList[projectName]?.["git_download"] || "..."}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>

    </Container>
  );
};

export default BuildProgressDisplay;
