export async function testSwApi() {
    try {

        const requestOptions = {
            method: 'GET'
        };
        
        const response = await fetch('/sw-api/test')
        const data = await response.json();
        console.log("testSwApi: ", data)

        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export async function gitlabApi(params) {
    try {
        const requestOptions = {
            method: 'POST'
        };

        const response = await fetch(`/sw-api/gitlab/${params.route}`)
        const data = await response.json();

        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function requestMenderArtifactAssembly(params) {
    try {
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/sw-api/assemble-mender-artifact', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error Posting data to API:', error);
        throw error;
    }
}


export async function requestArtifactBuildProgress(params) {
    try {
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/sw-api/get_build_progress', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error Posting data to API:', error);
        throw error;
    }
}

