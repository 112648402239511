import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const SwPickListDisplay = ({ swPickList }) => {
  if (!swPickList || Object.keys(swPickList).length === 0) {
    return null; // Return null if swPickList is empty
  }
  console.log("swPickList: ", swPickList)
  return (
    <Container className="mt-4 text-light">
      <h3 >Requested Software Loads</h3>
      <Table className="mt-4 text-light" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Source Type</th>
            <th>Source</th>
            <th>Pipeline</th>
          </tr>
        </thead>
        <tbody className="text-light">
          {Object.keys(swPickList).map((projectName) => {
            const { sourceType, source, pipeline } = swPickList[projectName];
            return (
              <tr className="text-light"key={projectName}>
                <td className="text-light">{projectName}</td>
                <td className="text-light">{sourceType}</td>
                <td className="text-light" colSpan={sourceType === 'release' ? 2 : 1}>{source}</td>
                {sourceType !== 'release' && <td className="text-light">{pipeline}</td>}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default SwPickListDisplay;
