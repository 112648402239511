import React, { useEffect, useState } from 'react';

const TestDataStatsBar = ({testStats, testFilter, setTestFilter}) => {

    const [totalError, setTotalError] = useState(false)
    const [measError, setMeasError] = useState(false)
    const [noFlow, setNoFlow] = useState(false)
    const [boundaryRatio, setBoundaryRatio] = useState(false)


    useEffect(() => {
        
        let newTotalError = testStats.total_error_percent || false
        let newMeasError = testStats.measurement_error_percent || false
        let newNoFlow = testStats.max_noflow_time_seconds || false
        let newBoundaryRatio =  testStats.droplog_boundary_ratio_avg || false

        setTotalError(newTotalError)
        setMeasError(newMeasError)
        setNoFlow(newNoFlow)
        setBoundaryRatio(newBoundaryRatio)

    }, [testStats]);

    const unitMap = {total_error_percent: '%',
        measurement_error_percent: '%', 
        drops_vol_avg: 'mL' , 
        droplog_boundary_ratio_avg: '',
        max_noflow_time_seconds: '' 
    }

    const renderStats = (colName, colTitle, data) => {
        
        const unit = unitMap[colName] || ''
        
        const min = data.min ? `${data.min }${unit}` : ''
        const max = data.max ? `${data.max }${unit}` : ''
        const avg = data.avg ? `${data.avg }${unit}` : ''
        const med = data.med ? `${data.med }${unit}` : ''
        const stdDev = !isNaN(Number(data.stdDev)) ? `${2*Number(data.stdDev)}${unit}` : ''
        const quartileCounts = data.quartileCounts ? `${data.quartileCounts }${unit}` : ''

        return (

            <span>
                <strong className="stats-title">{colTitle}</strong> {`[ `}
                        <span className="stats-value">
                            {min}</span> , <span className="stats-value">{max}</span> {` ] `} 
                <span className="stats-symbol">x̄</span> <span className="stats-value" title="Sample Mean">{avg}</span>
                <span className="stats-symbol" title="Standard Deviation">{`2σ`} </span><span className="stats-value" title="2 * Sample Standard Deviation">{stdDev}</span>
            </span> 
            
        ); 
    };

    
    return (
         <div className="stats-bar-container" >
           {totalError ? renderStats("total_error_percent", "Total Error", totalError) : <></>}
           {measError ? renderStats("measurement_error_percent", "Meas Error", measError) : <></>}
           {noFlow ? renderStats("max_noflow_time_seconds", "Longest NoFlow", noFlow) : <></>}
            {boundaryRatio ? renderStats("droplog_boundary_ratio_avg", "Boundary Ratio Avg", boundaryRatio) : <></>}
         </div>

    );
};

export default TestDataStatsBar;
