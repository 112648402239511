import React, { useState } from 'react';
import { Dropdown, InputGroup, Button, FormControl } from 'react-bootstrap';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterPicker = ({ col, vals, filterConfig, handleSelectChange, handleRangeSubmit, testFilter}) => {
  const [minValue, setMinValue] = useState(testFilter[col] && testFilter[col].gt || '');
  const [maxValue, setMaxValue] = useState(testFilter[col] && testFilter[col].lt || '');

  if (filterConfig.pickerType === "range") {
    
    return (
      <div style={{margin: "auto"}}>
        <InputGroup className="mb-3" size="sm" >
        <InputGroup.Text data-bs-theme="dark" id="inputGroup-sizing-sm">{filterConfig.title}</InputGroup.Text>
          <FormControl
            placeholder={Math.min(...vals.map(val => parseFloat(val.uniqueVal).toPrecision(2)))}
            aria-label="Minimum value"
            value={minValue}
            onChange={e => setMinValue(e.target.value)}
          />
          <FormControl
            placeholder={Math.max(...vals.map(val => parseFloat(val.uniqueVal).toPrecision(2)))}
            aria-label="Maximum value"
            value={maxValue}
            onChange={e => setMaxValue(e.target.value)}
          />
          <Button variant="outline-secondary" onClick={() => handleRangeSubmit(col, minValue, maxValue)}><FilterAltIcon /></Button>
        </InputGroup>
        </div>
    );
  } else {
    return (
      <Dropdown
        className="filter-bar-dropdown"
        onSelect={(eventKey) => handleSelectChange(col, eventKey)}
        disabled={!vals.length}
        key={`filter-bar-dropdown-${col}`}
      >
        <Dropdown.Toggle variant={testFilter[col] == filterConfig.default ? "outline-secondary" : "primary"} id={`dropdown-basic-${col}`} size="sm">
          {testFilter[col] === filterConfig.default ? filterConfig.title : testFilter[col]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={filterConfig.default}>{filterConfig.default}</Dropdown.Item>
          {vals.map((val) => (
            <Dropdown.Item key={`dropdown-item-${col}-${val.uniqueVal}`} eventKey={val.uniqueVal}>{val.uniqueVal} ({val.count})</Dropdown.Item>
          ))}
           {filterConfig.extraVals && filterConfig.extraVals.map((extraVal) => (<Dropdown.Item eventKey={extraVal}>{extraVal}</Dropdown.Item>))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default FilterPicker;
