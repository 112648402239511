import React, { useCallback, useEffect, useState, useMemo, useRef} from 'react';
import {
    ChonkyActions,
    FullFileBrowser,
    FileHelper,
    FileNavbar,

    } from 'chonky';
import Modal from 'react-bootstrap/Modal';
import '../styles/Styles.css';


console.log("TestLogBrowser component rendered");


const useFilesFromAPI = (currentFolderId = '') => {
    const [data, setData] = useState(null);


    console.log("useFilesFromAPI = (currentFolderId = ", currentFolderId)

    useEffect(() => {
        const fetchDirectoryStructure = async () => {
            const trimmedFolderId = currentFolderId.replace(/^\/usr\/share\/uploads\/log\//, '');
            console.log("fetchDirectoryStructure called for dir path", trimmedFolderId);
            const fetchURL = `https://exp.test-data.amgis.us/structure/${encodeURIComponent(trimmedFolderId)}`;
            console.log("Fetching:", fetchURL);
            try {
            const response = await fetch(fetchURL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            console.log('Request Made to express server.');

            const responseData = await response.json();
            console.log('Data received:', responseData);
            
            setData(responseData);
        } catch (error) {
            console.error("Error fetching directory contents:", error);
        }
        };

        fetchDirectoryStructure();

    }, [currentFolderId]);

    return data;
};

export const useFileActionHandler = (setCurrentFolderId, setPreviewContent) => {
    const initiateDownload = (path, isDirectory = false) => {
        console.log("useFileActionHandler -> initiateDownload -> ", path)
        let downloadURL;

        if (isDirectory) {
            // If it's a directory, use the zipAndDownload endpoint
            console.log("Directory Download requested for ", path)
            downloadURL = `https://exp.test-data.amgis.us/zipAndDownload/${encodeURIComponent(path)}`;
        } else {
            // If it's a single file, use the file endpoint
            console.log("File Download requested for ", path)
            downloadURL = `https://exp.test-data.amgis.us/file/${encodeURIComponent(path)}`;
        }

        const a = document.createElement('a');
        a.href = downloadURL;
        a.download = path.split('/').pop() || "downloaded_file"; // Derive the filename from the path or use a default
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    
    
    // existing code for other actions
    return useCallback(
        async (data) => {

            if (data.id === ChonkyActions.OpenFiles.id) {
                const { targetFile, files } = data.payload || {};
                const fileToOpen = targetFile ?? files[0];

                if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                    console.log("useFileActionHandler -> directory -> ", fileToOpen.id);
                    setCurrentFolderId(fileToOpen.id);
                    return;
                } else if (fileToOpen) {
                    console.log("useFileActionHandler -> file -> ", fileToOpen);
                    let originalFileId = fileToOpen.id.replace(/^\/usr\/share\/uploads\/log\//, '');
                    let trimmedFileId = originalFileId;

                    if (originalFileId.endsWith('.pgm')) {
                        trimmedFileId = originalFileId.replace(/pgm/g, "webp");
                    }

                    try {
                        const response = await fetch(`https://exp.test-data.amgis.us/file/${encodeURIComponent(trimmedFileId)}`, {
                            method: 'GET',
                            credentials: 'include'
                        });

                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        const contentType = response.headers.get('Content-Type');

                        if (contentType.startsWith('image/')) {
                          
                            const blob = await response.blob();
                                    
                            if (!blob.type.startsWith('image/') || !(blob.size > 100)) {
                                throw new Error('Fetched file is not an image');
                            }
                        
                            const objectURL = URL.createObjectURL(blob);
                            setPreviewContent({type: 'image',  content: objectURL, downloadPath: originalFileId});

                        } else if (contentType === 'text/plain' || contentType === 'text/csv' || contentType.endsWith('log') || contentType === 'application/json') {
                            const textContent = await response.text();
                            setPreviewContent({ type: 'text', content: textContent, downloadPath: originalFileId });
                        } else {
                            if (fileToOpen) {
                                initiateDownload(fileToOpen.path, fileToOpen.isDir);
                            } else {
                                console.error("No file selected for download.");
                            }
                            
                        }
                    } catch (error) {
                        console.error("Error fetching file contents:", error);
                    }
                }
            } else if (data.id === ChonkyActions.DownloadFiles.id) {
                const fileToDownload = data.state.selectedFiles[0]; 
                if (fileToDownload){
                    console.log("useFileActionHandler -> fileToDownload -> ", fileToDownload);
                    let originalFileId = fileToDownload.id.replace(/^\/usr\/share\/uploads\/log\//, '');
                    let trimmedFileId = originalFileId;
                    initiateDownload(trimmedFileId, fileToDownload.isDir)
                } else {
                   console.log("fileToDownload is not populated: ", fileToDownload)
                }
            }
        },
        [setCurrentFolderId, setPreviewContent]
    );
};


const TestLogBrowser = ({ instanceId, startingDirPath = '' }) => {
    const [currentFolderId, setCurrentFolderId] = useState(startingDirPath); // Initializing state with prop value or default to ''
    const data = useFilesFromAPI(currentFolderId);
    const files = data?.files || [];
    
    const [previewContent, setPreviewContent] = useState('')
   
    const handleClose = () => setPreviewContent('');

    
    // Hacky way of forcing descending default sort order... why..
    const fileBrowserRef = useRef(null);

    // Run this effect once after the component mounts
    useEffect(() => {
        if (fileBrowserRef && fileBrowserRef.current) {
        // Execute the default Chonky sort action
        fileBrowserRef.current.requestFileAction(ChonkyActions.SortFilesByName, undefined);
        }
    }, []); // Empty dependency array ensures this runs only once

    function getLastSegmentOfPath(path) {
        const segments = path.split('/');
        return segments[segments.length - 1] || segments[segments.length - 2] || 'Root';
    }
    
    const folderChain = useMemo(() => {
    
        if (data?.parents) {
            const folderChainReturn = data.parents.map(parent => {
                const folderName = getLastSegmentOfPath(parent.id); // Using the new function
                const trimmedFolderName = folderName.replace(/^\/usr\/share\/uploads\/log\//, '');
                return {
                    id: parent.id,
                    name: trimmedFolderName,
                    isDir: true,
                };
            });
            console.log("folderChain Generated -> ", folderChainReturn);
            
            return folderChainReturn;
        }
        return [];
    }, [data]);
    
    const handleFileAction = useFileActionHandler(setCurrentFolderId, setPreviewContent);
    
    const activeChonkyActions = [
        ChonkyActions.OpenFiles,
        ChonkyActions.DownloadFiles,
    ];

    const renderContent = () => {
        let preview;
        let warningMessage;
        
        const handleDownload = () => {

            const downloadURL = `https://exp.test-data.amgis.us/file/${encodeURIComponent(previewContent.downloadPath)}`;
            const a = document.createElement('a');
            a.href = downloadURL;
            a.download = previewContent.fileName || "downloaded_file"; // Using fileName from previewContent
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
    
        if (previewContent.type === 'image') {
            preview = (
                <div className="image-preview-wrapper">
                    <p>{previewContent.fileName}</p>
                    <img src={previewContent.content} alt="Preview" className="image-preview" />
                    <button onClick={handleDownload} className="download-btn">Download</button>
                </div>

            );
        } else if (previewContent.type === 'text') {
            preview = (
                <div>
                    <pre className="json-viewer">{previewContent.content}</pre>
                    <button onClick={handleDownload} className="download-btn">Download</button>
                </div>
            );
        } else {
            preview = <p>This file type cannot be previewed.</p>;
        }
    
        return preview;
    }
    
    return (
        <div className="testlog-browser-wrapper">
            {previewContent && (
                <div style={{ width: '100%', height: '100%' }}>
                    <Modal
                    show={true}
                    backdrop={true}
                    style={{ 
                        maxWidth: 'none', 
                        '--bs-modal-width': '90vw',
                        left: '0' 
                    }}>
                        <h2>File Preview</h2>
                       
                        <Modal.Body>
                        <button onClick={() => setPreviewContent(null)}>Close Preview</button>
                            <pre>{renderContent()}</pre>
                        <button onClick={() => setPreviewContent(null)}>Close Preview</button>
                        </Modal.Body>
                    </Modal>
                    
                    
                </div>
            )}
            <FullFileBrowser
                ref={fileBrowserRef}
                instanceId={instanceId}
                files={files}
                folderChain={folderChain}
                onFileAction={handleFileAction}
                onFileOpenClick={handleFileAction}
                thumbnailGenerator={(file) => file.thumbnailUrl || null}
                defaultFileViewActionId={ChonkyActions.EnableGridView.id}
                defaultSortActionId={ChonkyActions.SortFilesByName.id}
                fileActions={[...activeChonkyActions]}
            >
                <FileNavbar />
            </FullFileBrowser>
        </div>
    );
};

export default TestLogBrowser;
